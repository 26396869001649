import React, { useState } from "react"
import { graphql, Link } from "gatsby"
// import { PortableText } from "@portabletext/react"
// import Image from "gatsby-plugin-sanity-image"
import SanityImage from "gatsby-plugin-sanity-image"
import styled from "styled-components"
import {
  Container,
  Content,
  FeaturedBackgroundImage,
  // usePortableTextComponents,
} from "../components"
import Seo from "react-seo-component"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { PopupBanner } from "@features"

const Blog = ({ location, data }) => {
  console.log("[Blog index page] data", data)
  // console.log("[Blog index page]", data.allSanityPost.edges[0].node.title)
  // console.log("data.allSanityPost._rawBody:", data.allSanityPost._rawBody)

  // console.log("SANITY_DATASET", process.env.SANITY_DATASET)

  const {
    title,
    slug: { current: slug },
    heroImage: {
      // alt,
      // caption,
      heroImagePosition,
      heroImageHeight,
      heroImageHeightSm,
      heroImageHeightMd,
      heroImageHeightLg,
    },
  } = data.blogIndexPage

  const posts = data.allSanityPost.edges
  // console.log("posts:", posts)

  const getPublishedDate = date => {
    const tempDate = new Date(date)
    return tempDate.toLocaleDateString()
  }

  // const portableComponents = usePortableTextComponents()

  const {
    allSanityPopup: { edges: popupData },
  } = data
  // console.log("[Blog page template] popupData:", popupData)
  const activePopupExists = popupData.find(popup => popup.node.popupEnabled)
  // console.log("[Blog page template] activePopupExists:", activePopupExists)

  const { blogIndexPage: page } = data

  const [popupOpen, setPopupOpen] = useState(true)

  const {
    title: siteTitle,
    siteUrl,
    siteLanguage,
    siteLocale,
    twitterUsername,
    authorName,
  } = useSiteMetadata()

  const { pathname } = location
  // console.log("location:", location)
  // console.log("location.pathname:", pathname)

  const featureImage = page?.heroImage
    ? page?.heroImage?.asset?.gatsbyImageData
    : null
  return (
    <>
      <Seo
        title={title}
        titleTemplate={siteTitle}
        description={title}
        pathname={`${siteUrl}/${slug}`}
        article={true}
        siteLanguage={siteLanguage}
        siteLocale={siteLocale}
        twitterUsername={twitterUsername}
        author={authorName}
        modifiedDate={new Date(Date.now()).toISOString()}
      />
      <Container pathname={pathname}>
        {activePopupExists && popupOpen && (
          <PopupBanner
            setOpenPopup={setPopupOpen}
            popupData={activePopupExists}
          />
        )}
        {/* <Content> */}
        {featureImage && (
          <FeaturedBackgroundImage
            pathname={pathname}
            // image={featureImage ? featureImage : null}
            image={featureImage ? featureImage : null}
            title={title}
            opacity={0.5}
            heroImagePosition={heroImagePosition}
            heroImageHeight={heroImageHeight}
            heroImageHeightSm={heroImageHeightSm}
            heroImageHeightMd={heroImageHeightMd}
            heroImageHeightLg={heroImageHeightLg}
          />
        )}
        <Content>
          <StyledPostFeed>
            <div className="main">
              {/* {data.allSanityPost.edges.map((node) => ( */}
              {posts
                .filter(post => post?.node?.hidePost === false)
                .map(post => (
                  <div key={post?.node?.id} className="post">
                    {/* {console.log("post:", post)}
                    {console.log("post.node.body:", post?.node?.body)} */}
                    {post?.node?.heroImage && (
                      <Link to={`/blog/${post?.node?.slug?.current}`}>
                        <SanityImage
                          {...post?.node?.heroImage}
                          width={500}
                          style={{
                            width: "100%",
                            height: "250px",
                            // height: "100%",
                            // height: "auto",
                            objectFit: "cover",
                          }}
                          // alt="cover"
                          alt={post?.node?.heroImage?.alt}
                          className="post-image"
                        />
                      </Link>
                    )}
                    <Link
                      to={`/blog/${post.node.slug.current}`}
                      className="post-title"
                    >
                      <h1>{post.node.title}</h1>
                    </Link>
                    {/* <p>{post.node.slug.current}</p> */}
                    {/* <p>{post.node.publishedAt}</p> */}
                    <p className="publish-date">
                      {getPublishedDate(post.node.publishedAt)}
                    </p>
                    {/* {post.node.heroImage && <Image {...post.node.heroImage} alt="two players" />} */}

                    {/* <PortableText
                    value={post.node._rawBody}
                    components={portableComponents}
                  /> */}
                    {/* <Link to={`/blog/${post.node.slug.current}`}>
                    Read More...
                  </Link> */}
                    {/* <BlockContent blocks={post.node.body} /> */}
                    {/* {console.log("post.node.heroImage:", post.node.heroImage)} */}

                    {/* <SanityImage {...post.node.heroImage} /> */}

                    {/* <pre>{JSON.stringify(post.node, null, 2)}</pre> */}
                  </div>
                ))}
            </div>
            {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
          </StyledPostFeed>
        </Content>
      </Container>
    </>
  )
}

export default Blog

export const query = graphql`
  query {
    blogIndexPage: sanityPage(slug: { current: { eq: "blog" } }) {
      id
      title
      slug {
        current
      }
      heroImage {
        heroImageHeight
        heroImageHeightLg
        heroImageHeightMd
        heroImageHeightSm
        heroImagePosition
        asset {
          gatsbyImageData
        }
      }
    }
    allSanityPost(sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          ...SanityPost
          hidePost
        }
      }
    }
    allSanityPopup {
      edges {
        node {
          ...SanityPopup
        }
      }
    }
  }
`

const StyledPostFeed = styled.div`
  width: 100%;

  .main {
    /* margin: 25px; */
    /* width: 750px; */
    /* width: 100%;
    max-width: calc(100vw - 50px); */
    margin-top: 3.5rem;
    display: grid;
    gap: 5rem;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    @media only screen and (max-width: 768px) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 3rem;
    }
  }

  /* .post {
    display: inline-block;
  } */

  /* .post + .post {
    margin-top: 100px;
  } */

  .post-title {
    text-decoration: none;

    & h1 {
      font-size: 1.5rem;
    }
  }

  .publish-date {
    font-size: 1rem;
  }

  .post-image {
    border-radius: 10px;
    /* height: 250px; */
  }

  p {
    /* background: rebeccapurple;
    line-height: 45px; */
    margin: 0.5em 0 1em;
  }
`

// const StyledPost = styled.div`
//   margin: 25px 25px 100px 25px;

//   p {
//     /* background: rebeccapurple;
//     line-height: 45px; */
//     margin: 0.5em 0 1em;
//   }
// `
